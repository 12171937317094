import { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
// import {DeleteModalProps, DeleteModalState} from "../../types/deleteModal";

class DemoModal extends Component<{hide: Function, display: boolean}, {}> {

    render() {
        return (
            <>
                <Modal
                    show={this.props.display}
                    onHide={(): void => {this.props.hide()}}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Not allowed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    Demo version of app doesn't allow to upload or delete images from cloud. You can get full version at https://github.com/JakubKorytko/images-cloud
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={(): void => {this.props.hide()}}>Okay</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default DemoModal;